import Button from '@components/V3/Button'
import {
  GetCategoriesAndProductsWeb_CachedQuery,
  ProductListInformationFragment,
} from '@data/__generated__/types.main'
import {useWebsiteMenuInformation} from '@data/queries/website/websiteMenuInformation.main'
import classnames from '@helpers/misc/classnames'
import useOnEnterViewport from '@hooks/useOnEnterViewport'
import useLayout from '@page-components/Order/useLayout'
import useProductsLayout from '@page-components/Order/useProductsLayout'
import orderBy from 'lodash/orderBy'
import React from 'react'

import {useMemo} from 'react'
import {Suspense, useRef} from 'react'
import {useProductPaginationContext} from '../ProductPaginationContext'
import Product from './Product'
import useCardsV2 from './Product/useCardsV2'
import Skeleton, {ProductSkeleton} from './Skeleton'

import useAddStockInfoToProducts from './hooks/useAddStockInfoToProducts.main'
import useCategoryProducts, {PRODUCTS_PAGE_SIZE} from './hooks/useCategoryProducts.main'
import styles from './styles.module.css'

export interface CategoryProps {
  menuId: string
  category: GetCategoriesAndProductsWeb_CachedQuery['categories']['items'][0]
  hidePrice: boolean
  hideNotAvailableProducts: boolean
  isSearchResult?: boolean
}

//TODO: this logic should be sent to backend when hideNotAvailableProducts option is divided into to functionalities
export const filterUnavailableProducts =
  (hideNotAvailableProducts: boolean) => (product: ProductListInformationFragment) => {
    if (hideNotAvailableProducts && product.isOutOfStock) {
      return false
    }
    if (!product.availabilityAt) return false
    if (product.availabilityAt.notAvailableMessage) return true

    return !!product.availabilityAt.available
  }

function CategoryInner(props: CategoryProps) {
  const cardsV2 = useCardsV2()
  const {category} = props
  const {data, fetchMore, loading, hasNextPage} = useCategoryProducts(category._id, {
    excludeUnavailable: true,
    skip: props.isSearchResult,
  })
  const productsWithoutStock = data?.products
  const {productPaginationMode, sorting} = useProductPaginationContext()
  const {website, design} = useWebsiteMenuInformation()
  const productsLayout = useProductsLayout(website)
  const productCardDesign = design?.design.productCardDesign
  const layout = useLayout()
  const categoryProductsWithStock = useAddStockInfoToProducts(
    {categoriesIds: [category._id]},
    productsWithoutStock?.items,
    {
      skip: props.isSearchResult,
    },
  )
  const searchProductsWithStock = useAddStockInfoToProducts(
    {productsIds: category.products.map(product => product._id)},
    category.products,
    {
      skip: !props.isSearchResult,
    },
  )
  const sentinelRef = useRef(null)
  const productsWithStock = props.isSearchResult
    ? searchProductsWithStock
    : categoryProductsWithStock

  useOnEnterViewport({
    sentinelRef,
    onEnterViewport: productPaginationMode === 'auto' ? fetchMore : () => {},
    notifyMultipleTimes: true,
    rootMargin: 1000,
  })

  const products = useMemo(() => {
    if (!productsWithStock) return []

    const filterUnavailableProductsFn = filterUnavailableProducts(props.hideNotAvailableProducts)
    let productList = productsWithStock.filter(filterUnavailableProductsFn)

    if (sorting) {
      if (sorting.type === 'price') {
        productList = orderBy(productList, product => product.availabilityAt.finalPrice, [
          sorting.order,
        ])
      }

      if (sorting.type === 'name') {
        productList = orderBy(productList, ['name'], [sorting.order])
      }

      if (sorting.type === 'timesPurchased') {
        productList = orderBy(productList, ['timesPurchased'], [sorting.order])
      }
    }

    return productList
  }, [productsWithStock?.length, sorting, props.hideNotAvailableProducts])

  let className =
    productsLayout === 'compact'
      ? {
          mobile: 'col-xs-6',
          v1: 'col-xs-6 col-md-3',
          v2: 'col-xs-3 col-md-2',
        }[layout]
      : {
          mobile: 'col-xs-12',
          v1: 'col-xs-12 col-md-6',
          v2: 'col-xs-6 col-md-4',
        }[layout]
  className += ' menuProductCol'
  if (cardsV2) {
    className = ''
  }

  const renderProducts = () => {
    return products.map(product => {
      return (
        <div
          key={product._id}
          className={`${className} product-card shadow-[4px_0_20px_0px_rgba(0,0,0,0.08)]`}
        >
          <Suspense fallback={<ProductSkeleton />}>
            <Product
              productsLayout={productsLayout}
              productCardDesign={productCardDesign}
              hidePrice={props.hidePrice}
              product={product}
              menuId={props.menuId}
            />
          </Suspense>
        </div>
      )
    })
  }

  const isCardv2Compact = productCardDesign?.includes('vertical-compact')
  const cardvProductsLayoutStyle = classnames('flex flex-row flex-wrap gap-4', {
    'justify-start': isCardv2Compact,
    'justify-center md:justify-start': !isCardv2Compact,
  })
  const productsStyle = cardsV2 ? cardvProductsLayoutStyle : styles.products
  const unloadedProductCount =
    (data?.products?.totalCount || 0) - (data?.products?.items?.length || 0)

  return (
    <>
      {/* The id of the div bellow is intended as a unique match to Tiramisu's
    layout. Please do not change it. */}
      <div className={styles.container} id={`t111_${category._id}`}>
        <div className={styles.name}>{category.name}</div>
        <div className={`${productsStyle} products-container`}>
          {cardsV2 ? renderProducts() : <div className="row">{renderProducts()}</div>}
          {loading && (
            <>
              {Array.from({length: Math.min(unloadedProductCount, PRODUCTS_PAGE_SIZE)}).map(
                (_, index) => (
                  <div key={index} className={className}>
                    <ProductSkeleton />
                  </div>
                ),
              )}
            </>
          )}
        </div>
        {productPaginationMode === 'auto' ? (
          <div ref={sentinelRef} />
        ) : hasNextPage ? (
          <div className="flex justify-center primaryColor">
            <Button transparent className="my-4" onClick={fetchMore}>
              Ver más
            </Button>
          </div>
        ) : null}
      </div>
    </>
  )
}

export default function Category(props: CategoryProps) {
  return (
    <Suspense fallback={<Skeleton />}>
      <CategoryInner {...props} />
    </Suspense>
  )
}
